@import url('../src/assets/styles/normalize.css');

/* font-family: "Unbounded", sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap'); 

/* font-family: "Open Sans", sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Unbounded:wght@200..900&display=swap'); 

:root {
  --primary: #0070BD;
}

body {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
}

.app {
  max-width: 520px;
  padding-top: 34px;
  padding-bottom: 91px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  background: #F5F3EC;
}

.main {
  padding: 0 18px 0;
  height: 100%;
  overflow: auto;
}

.icon._distance {
  display: block;
  position: relative;
  height: 2px;
  background: #fff;
  margin: 0 11px;
}

.icon._distance:before {
  content: '';
  position: absolute;
  width: 11px;
  height: 16px;
  right: -11px;
  top: 0;
  margin-top: -7px;
  background: url('./assets/images/bg-denishtirmek.svg') no-repeat right center;
}

.icon._distance:after {
  content: '';
  position: absolute;
  width: 11px;
  height: 16px;
  left: -11px;
  top: 0;
  margin-top: -7px;
  background: url('./assets/images/bg-denishtirmek.svg') no-repeat left center;
}

.button {
  background: none;
  border: none;
  outline: none;
}

.input {
  background: none;
  border: none;
  outline: none;
}

