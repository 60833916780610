@mixin fluid-type($min-font-size: 12px, $mid-font-size: 21px, $max-font-size: 30px, $lower-range: 320px, $mid-range: 380px, $upper-range: 520px) {

    @media screen and (max-width: 300px) {        
        font-size: $min-font-size;
    }

    @media screen and (max-width: 360px) and (min-width: 300px) {        
        font-size: calc(#{$min-font-size} + #{(($mid-font-size / ($mid-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($mid-range / ($mid-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {        
        font-size: calc(#{$mid-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($mid-font-size / ($mid-font-size * 0 + 1)))} * ( (100vw - #{$mid-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($mid-range / ($mid-range * 0 + 1)))}));
    }
    
    @media screen and (min-width: 360px) and (max-width: 380px){
      font-size: $mid-font-size;
    }

    @media screen and (min-width: 520px) {        
        font-size: $max-font-size;
    }

}