@import '../../mixins.scss';

.header {
    position: sticky;
    top: 0;
    z-index: 1000;
    min-height: 51px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F3EC;
    text-align: center;
}

.header__back {
    position: absolute;
    left: 20px;
    top: 0px;
}

.header__back .button {
    width: 40px;
    height: 40px;
    background: #E8E7E0;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__back .button img {
    // filter: grayscale(100);
    opacity: .4;
    width: 70%;
    height: 70%;
}

.header__title {
    font-family: 'Unbounded';
    font-style: normal;
    font-weight: 800;
    @include fluid-type(15px, 18px, 21px);
    // font-size: 18px;
    line-height: 120%;
    color: #0070BD;
    // backdrop-filter: blur(2px);
    pointer-events: none;
    padding: 0 80px;
}