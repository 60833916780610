@import '../../mixins.scss';

.main._marshrutlar {
    margin: 0 -18px;
    overflow: hidden;
}

.marshrutlar__nav-slider {
    width: 100%;
    text-align: center;
    font-family: 'Unbounded';
    font-style: normal;
    font-weight: 800;
    @include fluid-type(13px, 16px, 19px);
    line-height: 125%;
    // font-size: 16px;
    // line-height: 20px;
    color: #B0AFA8;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
}

.marshrutlar__nav-slider .swiper-slide {
    width: 50%;
}

.marshrutlar__nav-slider .swiper-slide.swiper-slide-active {
    color: var(--primary);
}

.marshrutlar__slider {
    height: 100%;
}

.marshrutlar__slider .swiper-slide {
    /* min-height: 100%; */
}

.marshrutlar__content {
    padding: 0 18px 0;
    height: 100%;
    overflow: auto;
    /* min-height: 100%; */
}

.marshrutlar__list {
    display: flex;
    flex-direction: column;
    gap: 14px;
    position: relative;
}

.marshrutlar__list:before {
    content: '';
    position: absolute;
    right: 29px;
    top: 0;
    bottom: 0;
    width: 135px;
    background: url('../../assets/images/bg-pattern.svg') repeat-y center top;
    z-index: 100;
    pointer-events: none;
}