.navbar {
    position: fixed;
    /* margin-top: auto;
    position: sticky; */
    bottom: 0;
    left: 0;
    right: 0;
    height: 91px;
    min-height: 91px;
    background: #F5F3EC;
    z-index: 1000; 
    /* border-top: 2px solid var(--primary); */
    display: flex;
    justify-content: space-between;
    padding: 0 18px;
}

.navbar__link {
    width: 54px;
    height: 54px;
    border-radius: 16px;
    background: #E8E7E0;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 2px solid var(--primary); */
}

.navbar__link svg {
    stroke: var(--primary);
}

.navbar__link._tapmaq svg path {
    fill: var(--primary)
}

.navbar__link._active {
    background: var(--primary);
}

.navbar__link._active svg {
    stroke: #fff;
}

.navbar__link._tapmaq._active svg path {
    fill: #fff
}

.navbar__item {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.navbar__link:last-child {
    /* border-right: none; */
}