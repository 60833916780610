@import '../../mixins.scss';

.erier {
    padding-top: 9px;
}

.erier__lugat {
    margin-bottom: 24px;
}

.erier__map {
    height: 339px;
    margin-bottom: 14px;
}

.erier__audio {
    margin-bottom: 14px;
}

.erier__content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    /* font-size: 17px; */
    @include fluid-type(14px, 17px, 20px);
    line-height: 130%;
    /* line-height: 23px; */
    color: #000000;
    margin-bottom: 15px;
}

.erier__content-wrap {
    display: flex;
    flex-direction: column;
    gap: 9px;
    height: 70px;
    overflow: hidden;
}

.erier__content-wrap._full {
    height: auto;
}

.erier__content-more {
    text-align: right;
    margin-top: 9px;
    padding-bottom: 40px;
}

.erier__content-more .button {
    background: #E8E7E0;
    // color: #B0AFA8;
    border: 2px solid #0070BD;
    border-radius: 10px;
    padding: 6px 16px;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    width: 125px;
    color: #0070BD;
}

.erier__location {
    display: flex;
    flex-direction: column;
    gap: 14px;
    position: relative;
}

.erier__location:before {
    content: '';
    position: absolute;
    right: 29px;
    top: 0;
    bottom: 0;
    width: 135px;
    background: url('../../assets/images/bg-pattern.svg') repeat-y center top;
    z-index: 100;
    pointer-events: none;
}














.erier__gallery {
    margin-bottom: 14px;
    position: relative;
}

.erier__favorite {
    position: absolute;
    right: 16px;
    top: 15px;
    z-index: 20;
}






.erier__gallery-slider {
    overflow: auto;
    display: flex;
    gap: 18px;
    height: 339px;
    border-radius: 16px;
}

.erier__gallery-slider.swiper-initialized {
    overflow: hidden;
}

.erier__gallery-item {
    height: 339px;
}

.erier__gallery-item-title {
    font-family: 'Unbounded';
    @include fluid-type(18px, 22px, 26px);
    line-height: 100%;
    color: rgba(245, 243, 236, 1);
    font-weight: 800;
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 37px;
    z-index: 20;
    display: flex;
    justify-content: space-between;
}

.erier__gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // filter: grayscale(100);
}

.erier__gallery-item-title img {
    width: 25px;
    height: 25px;
}

.erier__gallery-slider .swiper-pagination {
    position: absolute;
    bottom: 18px;
    z-index: 20;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 6px;
}

.erier__gallery-slider .swiper-pagination-bullet {
    transition: all .3s;
    width: 15px;
    height: 3px;
    background: #B0AFA8;
    border-radius: 3px;
}

.erier__gallery-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 30px;
    background: #fff;
}