.top-locations {
    width: 100%;
    height: 100%;
    /* min-height: 100%; */
}

.top-locations__slider {
    overflow: auto;
    display: flex;
    gap: 18px;
    height: 100%;
}

.top-locations__slider.swiper-initialized {
    overflow: hidden;
}

.top-locations__item {
    width: 80%;
    min-width: 80%;
    height: 100%;
}

.top-locations__slider .swiper-wrapper {
    height: 100%;
}

.top-locations__slider .swiper-slide .top-locations__item {
    width: 100%;
}

.top-locations__slider .swiper-slide .top-locations__item .post._top .post__wrap {
    padding-bottom: 48px;
}

.top-locations__slider .swiper-pagination {
    position: absolute;
    bottom: 18px;
    z-index: 20;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 6px;
}

.top-locations__slider .swiper-pagination-bullet {
    transition: all .3s;
    width: 15px;
    height: 3px;
    background: #B0AFA8;
    border-radius: 3px;
}

.top-locations__slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 30px;
    background: #fff;
}