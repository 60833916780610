.entry {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1500;
    background: url('../../assets/images/bg-entry.svg') no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.entry__step-1 {
    padding-bottom: 20vh;
}

.entry__step-2 {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-top: 140px;
    padding-bottom: 91px;
    padding-left: 35px;
    padding-right: 35px;
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 27.24px;
    color: #F5F3EC;
}

.entry__step-2 img {
    width: 122px;
}

.entry__step-2 button {
    -webkit-appearance: none;
    outline: none;
    border: none;
    box-shadow: none;
    width: 125px;
    height: 54px;
    border-radius: 16px;
    background: #E8E7E0;
    font-family: 'Open Sans';
    font-size: 17px;
    font-weight: 600;
    line-height: 23.15px;
    text-align: center;
    color: #0070BD;
}