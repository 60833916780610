@import '../../mixins.scss';

.tapmaq {
    min-height: 100%;
    background: url('../../assets/images/bg-search.svg') no-repeat right bottom;
    margin: 0 -18px;
    padding: 9px 18px 0;
}

.tapmaq__form {
    position: relative;
    margin-bottom: 22px;
}

.tapmaq__not-found {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    @include fluid-type(14px, 17px, 20px);
    line-height: 130%;
    /* font-size: 17px;
    line-height: 23px; */
    color: #B0AFA8;
}

.tapmaq__form .button {
    width: 54px;
    height: 54px;
    border-radius: 16px;
    position: absolute;
    right: 0;
    top: 0;
    background: var(--primary);
}

.tapmaq__form .button svg {
    stroke: #fff;
    width: 80%;
}

.tapmaq__form .button svg path {
    fill: #fff;
}

.tapmaq__form .input {
    width: 100%;
    height: 54px;
    border-radius: 16px;
    border: 2px 0px 0px 0px;
    padding: 0 71px 0 17px;
    border: 2px solid var(--primary);
    font-family: 'Open Sans';
    @include fluid-type(14px, 17px, 20px);
    line-height: 130%;
    // font-size: 17px;
    font-weight: 600;
    // line-height: 23.15px;
    text-align: left;
    color: #000;
}

.tapmaq__form .input::placeholder {
    color: #D2D1CD;
}

.tapmaq__results {
    display: flex;
    flex-direction: column;
    gap: 9px;
}