.gallery {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #F5F3EC;
    opacity: 0;
    pointer-events: none;
    /* display: none; */
}

.gallery__buttons {
    position: fixed;
    z-index: 2020;
    right: 18px;
    top: 28px;
    display: flex;
    gap: 8px;
}

.gallery__buttons .button {
    width: 40px;
    height: 40px;
    background: #E8E7E0;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery._show {
    z-index: 2000;
    /* display: block; */
    opacity: 1;
    pointer-events: all;
}

.gallery__slider {
    height: 100%;
}

.gallery__slider .swiper-slide {
    /* min-height: 100%; */
    /* height: auto; */
}

.gallery__slider-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.gallery__slider-item img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
}

.gallery__slider .swiper-pagination {
    position: absolute;
    bottom: 18px;
    z-index: 20;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 6px;
}

.gallery__slider .swiper-pagination-bullet {
    transition: all .3s;
    width: 15px;
    height: 3px;
    background: #B0AFA8;
    border-radius: 3px;
}

.gallery__slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 30px;
    background: #fff;
}

.swiper-zoom-container {
    height: 100%;
}

.gallery__info {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 112, 189, .9);
    z-index: 100;
    overflow: auto;
    color: #fff;
    padding: 40px 120px 40px 40px;
    line-height: 150%;
}