@import '../../mixins.scss';

/* POST TOP */

.post._top {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none;
    border-radius: 16px;
    overflow: hidden;
}

.post._top .post__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.post._top .post__bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    // filter: grayscale(100);
    // opacity: .8;
}

.post._top .post__wrap {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 24px 16px;
}

.post._top .post__title {
    margin-bottom: 8px;
    font-family: 'Unbounded';
    font-style: normal;
    font-weight: 800;
    // font-size: 38px;
    @include fluid-type(32px, 38px, 44px);
    line-height: 109.33%;
    color: #F5F3EC;
}

.post._top .post__info {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #F5F3EC;
}


/* POST LOCATION */

.post._location {
    display: flex;
    align-items: flex-end;
    position: relative;
    padding: 15px 129px 15px 15px;
    width: 100%;
    min-height: 115px;
    background: #E8E7E0;
    // url('../../assets/images/route-bg-1.svg') no-repeat center top;
    background-size: auto 100%;
    border-radius: 16px;
    text-decoration: none;
}

.post._location .post__title {
    font-family: Unbounded;
    // font-size: responsive-font(16, 22);
    @include fluid-type(16px, 22px, 28px);
    font-weight: 800;
    line-height: 100%;
    text-align: left;
    color: var(--primary);
}

.post._location .post__preview {
    width: 115px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
    overflow: hidden;
}

.post._location .post__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.post._location._reverse {
    padding: 15px 15px 15px 129px;
    background: #E8E7E0;
    // url('../../assets/images/route-bg-2.svg') no-repeat center top;
    background-size: auto 100%;
}

.post._location._reverse .post__preview {
    left: 0;
    right: auto;
}

.post._location .post__favorite {
    position: absolute;
    width: 20px;
    right: 12px;
    bottom: 12px;
}

.post._location .post__favorite img {
    display: block;
}

/* @media screen and (max-width: 350px) {

    .post._location .post__title {
        font-size: 18px;
        line-height: 18px;
    }
    
} */