@import '../../mixins.scss';

.player {
    border: 2px solid #0070BD;
    border-radius: 16px;
}

#waveform > div:first-child {
    // display: none;
}

.player__wave {
    width: 100%;
    height: 30px;
}

.player__top {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
    border-bottom: 2px solid #0070BD;
}

.player__button .button {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #0070BD;
    display: flex;
    align-items: center;
    justify-content: center;
}

.player__duration {
    font-weight: 700;
    @include fluid-type(13px, 16px, 19px);
    line-height: 120%;
    // font-size: 16px;
    // line-height: 19px;
    color: #0070BD;
}

// .player__laoder {
//     position: absolute;
//     left: 0;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.player__bottom {
    display: flex;
    font-weight: 700;
    // font-size: 17px;
    @include fluid-type(14px, 17px, 20px);
    line-height: 150%;
    // line-height: 23px;
    color: #0070BD;
}

.player__bottom span {
    width: 100%;
    // text-align: center;
    border-right: 2px solid #0070BD;
    padding: 6px 16px;
}

.player__bottom span:last-child {
    border: none;
}

.player__bottom._vertical {
    flex-direction: column;
    @include fluid-type(13px, 16px, 19px);
    line-height: 130%;
    // font-size: 16px;
    // line-height: 22px;
    gap: 9px;
}

.player__bottom._vertical span {
    border: none;
    text-align: left;
}