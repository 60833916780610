@import '../../mixins.scss';

.acc {
    border: 2px solid #0070BD;
    border-radius: 16px;
    overflow: hidden;
    font-weight: 600;

    &__content {
        padding: 13px 16px;
        @include fluid-type(14px, 17px, 20px);
        line-height: 150%;
        color: #000000;
    }

    &__item {
        border-bottom: 2px solid #0070BD;
        &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            -webkit-appearance: none;

            background: none;
            color: #0070BD;
            border: none;
            padding: 13px 16px;
            gap: 16px;
            @include fluid-type(13px, 16px, 19px);
            line-height: 140%;
            cursor: pointer;
        }
        &:last-child {
            border: none;
        }
    }
}

.acc__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-appearance: none;
    background: #0070BD;
    border: none;
    color: #F5F3EC;
    padding: 13px 16px;
    gap: 16px;
    @include fluid-type(13px, 16px, 19px);
    line-height: 140%;
    cursor: pointer;

    &._active {

        background: none;
        color: #0070BD;
        border-bottom: 2px solid #0070BD;

    }
}